import { ExpandableContainer } from '../ExpandableContainer';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { COLLAPSE_COMPONENT, EXPAND_COMPONENT } from '../../icons';
import { Icon } from '../Icon';
import { DefaultHeader } from './DefaultHeader';
import './styles.css';

export const ExpandablePanel = (props) => {
  const {
    dataElementName = '',
    Header = DefaultHeader,
    HeaderButtons,
    headerClassName = '',
    className = '',
    bodyClassName = '',
    childrenClassName = '',
    title = '',
    children,
    isExpanded = true,
  } = props;
  const [expanded, setExpanded] = useState(isExpanded);
  const _bodyClassName = (() => {
    if (!expanded) {
      return undefined;
    }
    return bodyClassName
      ? `${bodyClassName} k-display-block`
      : 'k-display-block';
  })();

  return (
    <ExpandableContainer
      dataElementName={dataElementName}
      Header={Header}
      headerProps={{
        isExpanded: expanded,
        title,
        HeaderButtons,
        name,
      }}
      isExpanded={expanded}
      className={className ? `${className} k-display-block` : 'k-display-block'}
      bodyClassName={_bodyClassName}
      headerClassName={`${headerClassName} expandable-panel__header padding-horizontal-small padding-vertical-x-small`}
      onChange={() => setExpanded(!expanded)}
      Controller={({ isExpanded }) => {
        return isExpanded ? (
          <Icon
            dataElementName="expandablePanel__collapseButton"
            icon={COLLAPSE_COMPONENT}
          />
        ) : (
          <Icon
            dataElementName="expandablePanel__expandButton"
            icon={EXPAND_COMPONENT}
          />
        );
      }}
      preserveChildrenOnCollapse={true}
    >
      <div
        className={`${childrenClassName} expandable-panel__child`}
        data-element-name={dataElementName}
      >
        {children}
      </div>
    </ExpandableContainer>
  );
};

ExpandablePanel.propTypes = {
  Header: PropTypes.func,
  HeaderButtons: PropTypes.node,
  headerClassName: PropTypes.string,
  className: PropTypes.string,
  bodyClassName: PropTypes.string,
  childrenClassName: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  dataElementName: PropTypes.string,
};
